.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-bar {
  background-color: #a22c22;
  height: 120px;
  display: flex;
  margin-bottom: 50px;
}

.logo-container {
  display: flex;
  height: 170px;
  width: 170px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 85px;
}

.pgd-title {
  display: flex;
  flex-direction: row;
  color: rgb(56, 56, 56);
  font-weight: bold;
  font-size: 24px;
  border-width: 1px solid black;
}

.pgd-title span {
  line-height: 1.22;
}

.header-user-data-container {
  color: #f4f4f4;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.user-name-container {
  display: flex;
  justify-content: 'center';
}

.user-name-container .user-name {
  vertical-align: center;
  margin-left: 8px;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(56, 56, 56);
}

.white-button.MuiButton-outlined {
  border-color: #fff;
  color: #fff;
}

.white-button.MuiButton-text {
  /* border-color: #fff; */
  color: #f4f4f4;
}

.white-button.MuiButton-contained {
  background-color: #f4f4f4;
}

.privacy-check {
  font-size: 12px;
  line-height: 1.3;
}

.assembly-card.MuiPaper-outlined {
  min-height: 150px;
  display: flex;
}

.assembly-card.MuiPaper-outlined:hover {
  border-color: #a22c22;
}

.assembly-card .MuiCardContent-root {
  font-size: 24px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
}

.footer-title {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 8px;
}

.footer-grid-item {
  padding-top: 20px;
}

h3 {
  margin-bottom: 8px;
}

.begunje-5-terms a {
  text-decoration: none;
}

a:hover {
  color: #a12c21;
}

a {
  color: rgb(56, 56, 56);
}

body {
  color: rgb(56, 56, 56);
}

.paper {
  padding: 24px;
  padding-top: 12px;
  margin-bottom: 32px;
}

.paper h3 {
  margin-top: 0;
}

.paper h4 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.carousel-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  z-index: -2;
}

@media only screen and (max-width: 600px) {
  .header-112 {
    justify-content: center !important;
    margin-bottom: 16px !important;
  }
}

.cta-box div:hover {
  color: #a12c21 !important;
}
